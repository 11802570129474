/* These are global styles for the whole app */
@import '@nerdunited-switch/switchpay-components/dist/main.css';

:root,
html,
body {
  --navbar-height: 70px;
  --drawer-width: 240px;
  --app-bar-height: 64px;
  padding: 0;
  margin: 0;
  font-family: 'Lato', sans-serif;
  position: relative;
  overscroll-behavior: none;
}

:root {
  --ease: cubic-bezier(0.77, 0, 0.175, 1);
}

body {
  background-color: var(--mui-palette-surfaces-layerOne);
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

.navIcon {
  fill: var(--mui-palette-text-heading);
  height: 14px;
  width: auto;
}

.react-pdf__Page {
  width: 90%;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.5);
  margin: 16px auto;
}

.react-pdf__Page__canvas {
  margin: 0 auto;
  width: 80% !important;
  height: 100% !important;
}
.react-pdf__Page__textContent {
  background-color: transparent;
}

@media (max-width: 767px) {
  .txn-history__table {
    width: 100%;
  }
}

@media (max-width: 767px) {
  .transaction-history__no-data {
    margin-top: 20px;
  }
}

/* phone input override */
.PhoneInputInput {
  outline: none;
}
