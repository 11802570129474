.container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  min-width: 24px;
  border-radius: 50%;
  font-weight: 700;
  font-size: 14px;
  line-height: 21px;
}
