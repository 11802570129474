.wrapper {
  display: flex;
  align-items: flex-start;
}

.crumbWrapper {
  display: flex;
  align-items: center;
}

.crumb {
  color: var(--mui-palette-text-body);
  font-weight: 400;
  padding-inline: 12px;
}

.crumb:first-child {
  padding-left: 0;
}

.activeCrumb {
  composes: crumb;
  color: var(--mui-palette-text-bodyDark);
  font-weight: 600;
  padding-right: 0;
}

.seperator {
  color: var(--mui-palette-text-body);
  user-select: none;
}

.houseIcon {
  fill: var(--mui-palette-text-body);
  height: 18px;
}

.anchor {
  height: 24px;
  display: flex;
  align-items: center;
}
