.criteriaWrapper {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  margin-bottom: 24px;
}

.labelText {
  margin-bottom: 8px;
}

@media (max-width: 575px) {
  .criteriaWrapper {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-row-gap: 10px;
    grid-column-gap: 5px;
    margin-bottom: 24px;
  }
}
