.pageWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 32px;
}

.header {
  font-size: 32px;
  font-weight: 700;
  padding-bottom: 32px;
}

.subText {
  padding-bottom: 16px;
}

.button {
  margin-bottom: 32px;
}

.image {
  max-width: 400px;
  max-height: 403px;
}

.lottie {
  max-width: 400px;
  max-height: 403px;
}

@media (max-width: 767px) {
  .pageWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    max-width: 350px;
    margin: 0 auto;
    padding: 16px;
  }

  .header {
    font-size: 24px;
    font-weight: 700;
    padding-bottom: 32px;
  }

  .subText {
    padding-bottom: 14px;
  }
  .buttonRow {
    width: 100%;
    margin: 32px 0;
  }
  .button {
    width: 100%;
  }

  .mobileImageContainer {
    display: flex;
    justify-content: center;
  }

  .image {
    max-width: 300px;
  }

  .lottie {
    width: 100%;
    max-width: 300px;
  }
}
