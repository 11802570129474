.wrapper {
  width: fit-content;
  min-width: 200px;
  padding: 24px;
}

.main {
  display: flex;
}

.aside {
  margin-right: 16px;
}

.body {
  display: flex;
  flex-direction: column;
}

.buttonRow {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-top: 32px;
  gap: 16px;
}

.bodyText {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.megaphoneIcon {
  fill: var(--mui-palette-primary-600);
}

@media (max-width: 520px) {
  .main {
    flex-direction: column;
  }

  .buttonRow {
    flex-direction: column;
    margin-top: 16px;
  }
}
