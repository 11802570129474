.copy {
  max-width: var(--max-width);
  cursor: copy;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 32px;
  padding: 0px 16px;
  border-radius: 4px;
  background-color: var(--mui-palette-surfaces-layerTwo);
  border: 1px solid var(--mui-palette-surfaces-layerTwoBorder);
}

.container {
  text-align: left;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.value {
  max-width: 100%;
  padding-right: 8px;
  font-size: 14px;
  color: var(--mui-palette-grey-900);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.iconBox {
  width: 16px;
  height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.icon {
  width: 16px;
  height: 16px;
  fill: var(--mui-palette-grey-600);
}

.content {
  padding: 12px 16px;
  border-radius: 4px;
}
