.page {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 32px 24px;
  background-color: var(--mui-palette-surfaces-layerOne);
}

.content {
  flex-shrink: 0;
  display: block;
  width: 100%;
  margin: 0 auto;
  max-width: 1056px;
}

@media (max-width: 820px) {
  .page {
    padding: 24px 16px;
  }
}
