.priceContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  font-size: 16px;
  font-weight: 700;
  width: 100%;
}

.priceContainerRow {
  composes: priceContainer;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.usd {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: inherit;
  width: 100%;
  padding-bottom: 8px;
  margin: 8px 0 0 0;
}

.usdRow {
  composes: usd;
  align-items: center;
  width: fit-content;
  padding-bottom: 0;
}

.usdAmount {
  font-size: inherit;
  padding-right: 8px;
}

.eth {
  composes: usd;
  border-bottom: none;
  margin: 0;
  padding-bottom: 0;
  padding-top: 8px;
}

.ethRow {
  composes: usdRow;
  margin-left: 8px;
  border-right: none;
  align-items: center;
}

.ethAmount {
  composes: usdAmount;
  border: none;
}

.iconContainerUSD {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 16px;
  min-height: 16px;
  width: 16px;
  min-width: 16px;
  margin-right: 8px;
  border-radius: 50%;
  background-color: var(--mui-palette-success-main);
}

.iconContainerETH {
  composes: iconContainerUSD;
  background-color: var(--mui-palette-info-main);
}

.icon {
  fill: var(--mui-palette-common-white);
  max-width: 10px;
  max-height: 10px;
}

.coinIcon {
  border-radius: 50px;
  width: 16px;
  height: 16px;
}

@media screen and (max-width: 767px) {
  .priceContainer {
    display: flex;
    align-items: center;
    font-size: 16px;
  }

  .iconContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .eth .iconContainer {
    margin-left: 0px;
  }
}
