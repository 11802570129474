.card {
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 32px;
  border-radius: 5px;
  width: 700px;
  box-shadow: #0000002e 0px 3px 12px;
  background-color: white;
}

.closeButton {
  position: absolute;
  top: 16px;
  right: 16px;
}

.closeIcon {
  width: 18px;
  height: 18px;
  fill: black;
}

.card header {
  margin-bottom: 32px;
}

.inputSection {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 24px;
}

.inputRow {
  width: 364px;
  margin-right: 8px;
}

.gridList {
  list-style-type: none;
}

.item {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.container {
  display: flex;
  align-items: center;
  min-width: 0px;
}

.deleteButton {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 8px;
}

.deleteIcon {
  width: 14px;
  height: 14px;
  fill: var(--mui-palette-grey-600);
}

@media (max-width: 767px) {
  .card {
    border-radius: 0px;
    width: 100%;
    box-shadow: none;
  }

  .gridList {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}

@media (max-width: 626px) {
  .gridList {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
}

@media (max-width: 410px) {
  .inputSection {
    flex-direction: column;
  }

  .inputRow {
    width: 100%;
    margin: 0 0 8px 0;
  }
}
