.wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  flex-wrap: wrap;
}

.header {
  white-space: nowrap;
}

.childrenWrapper {
  flex-basis: 100%;
}

@media (min-width: 600px) {
  .childrenWrapper {
    flex-basis: auto;
  }
}
