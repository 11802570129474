@keyframes loading {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.wrapper {
  --spinnerColor: var(--mui-palette-primary-light);
  --spinnerSize: var(-spinnerSize);
  background-color: transparent;
}

.spinner {
  height: 28px;
  width: 28px;
  border: solid 4px var(--spinnerColor);
  border-bottom-color: transparent;
  border-radius: 50%;
  animation: loading 2s linear infinite;
}
